@font-face {
  font-family: "NHaasGroteskDSPro55Rg";
  src: local("NHaasGroteskDSPro55Rg"),
    url(./assets/fonts/NHaasGroteskDSPro-55Rg.otf) format("opentype");
}

@font-face {
  font-family: "NHaasGroteskDSPro65Md";
  src: local("NHaasGroteskDSPro65Md"),
    url(./assets/fonts/NHaasGroteskDSPro-65Md.otf) format("opentype");
}

@font-face {
  font-family: "NHaasGroteskTXPro55Rg";
  src: local("NHaasGroteskTXPro55Rg"),
    url(./assets/fonts/NHaasGroteskTXPro-55Rg.otf) format("opentype");
}

@font-face {
  font-family: "NHaasGroteskTXPro65Md";
  src: local("NHaasGroteskTXPro65Md"),
    url(./assets/fonts/NHaasGroteskTXPro-65Md.otf) format("opentype");
}

strong {
  font-family: "NHaasGroteskTXPro65Md";
}
